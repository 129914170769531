<script setup>
import { computed, reactive, ref, onMounted, onUpdated, inject } from "vue";
import SecuredBadge from "./SecuredBadge.vue";

const props = defineProps(['fileData','channel','openFileId']);
const emit = defineEmits(['openInModal']);
const shareLink = inject('shareLink');

const visible = computed(()=>{
    if(props.fileData.parent_file_id !== undefined) {
        return true
    }
    return props.fileData.file_children.length > 0
});

const direct_link = computed(()=>{
  return props.fileData.parent_file_id === props.openFileId;
});

const secured = computed(()=>{
  return props.channel === 'private';
});

const file_title = computed(()=>{
  let pattern = /\d{2}_|\.\w{2,7}/ig;
  return props.fileData.title.replace(pattern, '').replace(/_/ig, ' ');
});

const href = computed(()=> {
  if(direct_link.value){
    return props.fileData.url;
  }
  return ['',props.channel, props.fileData.file_id, props.fileData.file_name].join('/');
});

const { icon_set, getIcons } = inject('icons');

const icons = reactive(Object.assign({},icon_set));

const setIcon = function () {
  let icons_list = getIcons(props.fileData);
  let icons_keys = Object.keys(icons);
  icons_keys.forEach((icons_key) => {
      icons[icons_key] = icons_list[icons_key];
  });
};

const shareUrl = function () {
  let url = new URL(href.value,window.location.origin);
  shareLink(url.toString());
};

const openFile = function (href, isMobile) {
  if(!openInModal(href, isMobile)){
    window.open(href,"_blank");
  }else{
    emit('openInModal', {file:props.fileData,href:href,title:file_title});
  }
}

const openInModal = function (href, isMobile) {
  let filetype = props.fileData.filetype.filetype_id;
  let link_pattern = new RegExp('.*\.link');
  if(filetype === 'FILE_PDF' && isMobile) {
    return false;
  }else if(filetype === 'FILE_LINK' && link_pattern.test(href)) {
    return false
  }else if(filetype !== 'FILE_VIDEO' && filetype !== 'FILE_PDF' && filetype !== 'FILE_LINK' && filetype !== 'FILE_IMAGE'){
    return false;
  }
  return true;
}

onMounted(()=>{
    setIcon();
});

onUpdated(()=>{
    setIcon();
    document.activeElement.blur();
});

</script>

<template>
  <router-link :to="href" class="list-group-item list-group-item-action icon-link overflow-x-hidden px-lg-4 px-sm-1 py-2" v-if="!direct_link && visible">
      <i class="icon bi me-lg-4 me-sm-2" :class="icons"></i>
      {{file_title}}
      <SecuredBadge :class="['position-relative','float-end','top-50','translate-middle', 'secured','position-absolute', 'end-0']" v-if="secured"/>
  </router-link>
  <div class="list-group-item list-group-item-action overflow-x-hidden px-lg-4 px-sm-1 py-2" v-if="direct_link && visible">
      <a :href="href" @click.prevent="openFile(href, $isMobile())" target="_blank" class="icon-link col-11 col-lg-12 col-md-11 col-sm-6">
          <i class="icon bi me-lg-3 me-sm-1" :class="icons"></i>
          {{file_title}}
      </a>
    <a href="#" @click.prevent="shareUrl" class="btn btn-outline-light btn-share end-0 top-50 translate-middle-y position-absolute">
      <i class="icon share">
        <SecuredBadge :class="['position-relative',{'secured': secured}]" v-if="secured"/>
      </i>
    </a>
  </div>

</template>