
    import { createApp } from 'vue';
    import { createRouter, createWebHashHistory } from "vue-router";
    import VueMobileDetection from 'vue-mobile-detection';
    import App from './assets/javascript/App.vue';
    import Content from "./assets/javascript/components/Content.vue";
    import NotFound from "./assets/javascript/components/NotFound.vue";

    const routes = [
        { path: '/:pathMatch(.*)*', name: 'NotFound', component: NotFound },
        { path: '/', name: 'public', component: Content, alias: '/public' },
        { path: '/private', name: 'private', component: Content },
        { path: '/public/:file_id/:file_name', name: 'public.directory', component: Content },
        { path: '/private/:file_id/:file_name', name: 'private.directory', component: Content }
    ];
    const router = createRouter({
      history: createWebHashHistory(),
      routes
    })

    const app = createApp(App);
    app.use(VueMobileDetection)
    app.use(router);
    app.provide('router',router);
    app.mount('#app');

  