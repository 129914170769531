<script setup>
import {computed} from "vue";

const props = defineProps(['fileData','href']);

const media_url = computed(()=>{
  if(is_youtube){
    return props.href
  }
  return new URL(props.href,window.location.origin);
})
const get_filetype = function () {
  if(props.fileData !== null){
    if(Object.hasOwn(props.fileData, 'filetype') ){
      return props.fileData.filetype.filetype_id;
    }
  }
  return '';
};

const is_video = computed(()=>{
  return get_filetype() === 'FILE_VIDEO';
});

const is_pdf = computed(()=>{
  return get_filetype() === 'FILE_PDF';
});

const is_image = computed(()=>{
  return get_filetype() === 'FILE_IMAGE';
});

const is_youtube = computed(()=>{
  if(get_filetype() === 'FILE_LINK'){
    return props.href.indexOf('.youtube');
  }
  return false;
});

</script>

<template>
  <div class="content-video" v-if="is_video">
    <video width="100%" height="100%" controls autoplay class="align-content-center">
      <source :src="media_url" type="video/mp4">
      Your browser does not support the video tag.
    </video>
  </div>
  <div class="content-pdf" v-if="is_pdf">
    <iframe width="100%" height="100%" :src="media_url"></iframe>
  </div>
  <div class="content-image" v-if="is_image">
    <img :src="media_url" :alt="fileData.title" />
  </div>
  <div class="content-youtube" v-if="is_youtube">
    <iframe width="100%" height="100%" :src="media_url" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share; allow-same-origin" allowfullscreen ></iframe>
  </div>
</template>