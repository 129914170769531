<script setup>
import {computed, inject, ref, watch} from "vue";
import SecuredBadge from "./SecuredBadge.vue";

const emit = defineEmits(['shareLink']);

const { CHANNEL_PRIVATE, CHANNEL_PUBLIC, channel_selected, selectChannel } = inject('channel');
const router = inject('router');
const { getAuthBearer,userLoggedIn,openLogin } = inject('userApi');

const directory_depth = ref(1);
const share_modal = ref();

const registerShareModal = function (modal) {
  share_modal.value = modal;
};

watch(router.currentRoute,(ro)=>{
    let route_elements = ro.name.split('.');
    let channel = route_elements[0];
    directory_depth.value = route_elements.length;
    selectChannel(channel);
});

watch(userLoggedIn, (is_logged_in)=>{
  let route_elements = channel_selected.value.split('.');
  let channel = route_elements[0];
  if(channel === CHANNEL_PRIVATE && !is_logged_in){
    router.replace({ name: CHANNEL_PUBLIC });
  }
});

const openPrivateChannel = function () {
  if(userLoggedIn.value){
    router.replace({ name: CHANNEL_PRIVATE });
  }else{
    openLogin(CHANNEL_PRIVATE);
  }
};

const navigateBack = function () {
  router.replace({ name: channel_selected.value });
};

const shareLink = function () {
  emit('shareLink',document.URL);
};

</script>
<template>
    <teleport to="body">
        <div class="footer container-fluid position-fixed bottom-0 z-2" :class="{'private': CHANNEL_PRIVATE === channel_selected}">
            <div class="row justify-content-center">
                <div class="col-10 align-self-center text-center" v-if="directory_depth === 1">
                    <a href="/#/" class="btn btn-footer" :class="{'active': CHANNEL_PUBLIC === channel_selected}" role="button"><i class="icon public"></i></a>
                    <a href="/#/private" class="btn btn-footer" :class="{'active': CHANNEL_PRIVATE === channel_selected}" @click.prevent="openPrivateChannel" role="button"><i class="icon private"><SecuredBadge :class="'secured'"/></i></a>
                </div>
                <div class="col-10 align-self-center text-center" v-else>
                    <a href="#" @click.prevent="navigateBack" class="btn btn-footer" >
                      <i class="icon back"></i>
                    </a>
                    <a href="#" @click.prevent="shareLink" class="btn btn-footer">
                      <i class="icon share">
                        <SecuredBadge :class="['position-relative','secured']" v-if="CHANNEL_PRIVATE === channel_selected"/>
                      </i>
                    </a>
                </div>

            </div>
        </div>
    </teleport>

</template>