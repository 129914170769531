<script async setup>
import {ref, onMounted, onUpdated, watch, inject, reactive, provide, computed} from "vue";
import FileList from "./FileList.vue";
import Modal from "./bootstrap/Modal.vue";
import ModalContentMedia from "./ModalContentMedia.vue";
import NotFound from "./NotFound.vue";

const emit = defineEmits(['setOpenFileIcon']);
const { CHANNEL_PRIVATE, CHANNEL_PUBLIC, channel_selected, selectChannel } = inject('channel');
const router = inject('router');
const { getAuthBearer,userLoggedIn,openLogin } = inject('userApi');
const { file_filter, setFilter } = inject('filter');
const contentModal = ref(null);
const fileModalDataInit = {
  fileData: null,
  href: '',
  title: ''
};
const fileModalData = ref(Object.assign({},fileModalDataInit));

const icon_set = {
    city: false,
    casino: false,
    stadium: false,
    airport: false,
    harbour: false,
    industry: false,
    logistic: false,
    perimeter: false,
    about: false,
    document: false,
    camera: false,
    panomera: false,
    domera: false,
    project: false,
    video: false,
    youtube: false,
    pdf: false,
    image: false,
    ppt: false,
    security: false,
    software: false,
    integration: false,
    link: false,
    website: false,
    default: false
};

const getIcons = function (file_data) {
    let filetype = file_data.filetype.filetype_id;
    let filetype_pattern = file_data.filetype.filetype_pattern
    let icons_keys = Object.keys(icon_set);
    let icons = Object.assign({},icon_set);
    let is_root = file_data.hasOwnProperty('file_children');

    let icon_pattern = file_data.file_name;
    if( filetype !== 'DIRECTORY'){
      icon_pattern = filetype.split('FILE_')[1];
    }else if(!is_root){
      icon_pattern = 'project';
    }
    icons_keys.forEach((icon_class) => {
      let match_class = new RegExp(icon_class);
      if(filetype_pattern !== undefined){
        let match_pattern = new RegExp(filetype_pattern);
        icons[icon_class] = match_pattern.test(file_data.url) ? match_class.test(file_data.url):false;
      }
      icons[icon_class] = !icons[icon_class] ? match_class.test(icon_pattern.toLowerCase()):true;
    });
    return icons
};

provide('icons',{
  icon_set,
  getIcons
});

const file_list = ref({
    channels:{
        public:[],
        private:[]
    },
    open_file_id: false,
    list: [],
    depth: 1
});

watch(router.currentRoute,(ro)=>{
    parseRoute();
});

const parseRoute = function () {
  let route_segments = router.currentRoute.value.name.split('.');
  let channel = route_segments[0];
  let secured = channel === CHANNEL_PRIVATE;
  selectChannel(channel)
  file_list.value.depth = route_segments.length;
  if(secured && userLoggedIn.value || !secured){
    loadFile(secured);
  }else{
    openLogin(router.currentRoute.value.path);
    router.replace({ name: CHANNEL_PUBLIC });
  }
};

const filterListItem = function (item) {
  let filter_matched = true;
  Object.entries(file_filter.value).forEach(([filter_type, filter]) => {
    if(filter.active){
      if(filter_type === 'language'){
        const filter_rule =  /_(de|en)(\.|_|$)/mgi;
        const filter_exec = new RegExp(`_(${filter.value})(\.|_|$)`, 'gmi');
        if(filter_rule.exec(item.file_name) !== null){
          filter_matched = filter_exec.exec(item.file_name) !== null;
        }else{
          filter_matched = true;
        }
      }
    }
  });
  return filter_matched;
}

const filterList = function (file_list) {
  let file_list_filtered = [];
  file_list.forEach((item) => {
    if(filterListItem(item)){
      file_list_filtered.push(item);
    }
  })
  return file_list_filtered;
}

const setActiveList = function() {
    let file_id = getSelectedFileId();
    if(file_id){
        file_list.value.open_file_id = file_id;
        let children = getFileChildren(file_list.value.open_file_id);
        if(children.length > 0){
          let open_file = getOpenFile(file_id);
          emit('setOpenFileIcon', getIcons(open_file[0]));

          file_list.value.list = filterList(children);
        }else{
          router.replace({name: 'NotFound'});
        }
    }else{
        emit('setOpenFileIcon', Object.assign({},icon_set));
        file_list.value.open_file_id = false;
        file_list.value.list =  getActiveChannelList();
    }
};

const list_empty = computed(()=>{
  let item_count = 0;
  for (let listItem of file_list.value.list) {
    if(listItem.parent_file_id !== undefined) {
        item_count++;
        continue;
    }
    if(listItem.file_children.length > 0){
      item_count++;
    }
  }
  return item_count === 0;
})

const getSelectedFileId = function () {
  let file_id = false;
  if(Object.hasOwn(router.currentRoute.value.params, 'file_id')){
    file_id =  router.currentRoute.value.params.file_id;
  }
  return file_id;
};

const getActiveChannelList = function () {
    return file_list.value.channels[channel_selected.value]
};

const getOpenFile = function (file_id) {
    let active_channel_list = getActiveChannelList();
    return active_channel_list.filter((file) => {
        return file.file_id === file_id;
    });
};

const getFileChildren = function (file_id){
    let children = [];
    let open_file = getOpenFile(file_id);
    if(open_file.length > 0){
      children = open_file[0].file_children
    }
    return children;
};

async function fetchData(url_params){
  const dataResponse = await fetch(url_params, {
    method: "GET",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': getAuthBearer()
    },
  });

  if(!dataResponse.ok){
    throw new Error(`${dataResponse.statusText} (${dataResponse.status})`);
  }

  return await dataResponse.json();
}

const loadFile = function (secured = false) {
    let active_channel_list = getActiveChannelList()
    if(active_channel_list.length === 0 || channel_selected.value === CHANNEL_PRIVATE){
        fetchData(`/file/${channel_selected.value}`,).then(data => {
          file_list.value.channels[channel_selected.value] = data;
          setActiveList();
      }).catch(error => {
            console.error(error.message);
        }).finally(() => {
          // loadingState.value = false;
        });
    }else{
        setActiveList();
    }
};

watch(file_filter.value,(filter)=>{
    setActiveList();
});

const modalCreated = function (modal) {
  contentModal.value = modal;
  contentModal.value._element.addEventListener('hidden.bs.modal', event => {
    fileModalData.value = Object.assign({},fileModalDataInit);
  })
};

const openInModal = function ({file,href,title}) {
  fileModalData.value.fileData = file;
  fileModalData.value.href = href;
  fileModalData.value.title = title;
  contentModal.value.show();
};

onMounted(()=>{
    parseRoute();
});
onUpdated(()=>{
    // parseRoute();
});

</script>

<template>
  <div class="row content" :class="{'directory-root': file_list.depth < 2}">
    <div class="list-group list-group-flush pe-0">
        <NotFound v-if="list_empty"></NotFound>
        <FileList v-for="(file, index) in file_list.list" :file-data="file" :open-file-id="file_list.open_file_id" :channel="channel_selected" @share-link="shareLink" @open-in-modal="openInModal"></FileList>
    </div>
  </div>
  <teleport to="body">
    <Modal :title="fileModalData.title" :share="fileModalData.href" :size="'modal-fullscreen'" :footer-close="true" @modal-created="modalCreated">
      <ModalContentMedia :file-data="fileModalData.fileData" :href="fileModalData.href"></ModalContentMedia>
    </Modal>
  </teleport>
</template>