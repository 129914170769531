<script setup>
import {computed, inject, reactive, ref} from 'vue'

  const { file_filter, setFilter } = inject('filter');
  const icons = reactive({});
  const icon_visible = computed(()=>{
    let show = false;
    let icons_keys = Object.keys(icons);
    icons_keys.forEach((icon_class) => {
      if(icons[icon_class] === true){
        show = true;
        return show;
      }
    });
    return show;
  })
  const setIcons = function (icons_set){
    Object.assign(icons,icons_set);
  };
  defineExpose({setIcons});

  const toggleFilter = function(type, value) {
    if(file_filter.value.hasOwnProperty(type)){
      let filter = Object.assign({}, file_filter.value);
      if(filter[type].value === value){
        filter[type].active = false;
        filter[type].value = '';
      }else{
        filter[type].active = value.length > 1;
        filter[type].value = value;
      }

      setFilter(filter);
    }else{
      console.error('filter type is not defined', type);
    }
  };

  const filter_active = computed(() => {
    let active = false;
    Object.entries(file_filter.value).forEach(([filter_type, filter]) => {
      if(filter.active){
        active = true;
      }
    });
    return active;
  })

</script>

<template>
  <div class="row w-100 justify-content-center header position-fixed start-0 top-0 z-2 g-0 shadow">
      <div class="col">
        <img src="../../images/dallmeier_logo_white.svg" alt="Dallmeier electronic" class="logo">
        <h3>Presentations</h3>
        <i class="icon header mx-auto" :class="icons" v-show="icon_visible"></i>

        <div class="filter w-100 mx-auto">
          <div class="btn-group dropdown text-end" v-show="icon_visible">
            <button type="button" class="btn btn-outline-light btn-lg dropdown-toggle btn-filter align-self-end" :class="{'filter_active': filter_active}" data-bs-toggle="dropdown" aria-expanded="false">
              <i class="icon filter"></i>
            </button>
            <ul class="dropdown-menu">
              <li v-for="(title, filter) in file_filter.language.options">
                <a class="dropdown-item" :class="{active:file_filter.language.value === filter}" href="#" @click.prevent="toggleFilter('language',filter)">{{title}}</a>
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
</template>