<script setup>
import { provide, ref, computed, inject, onMounted } from 'vue';
import Auth from "./components/Auth.vue";
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import MessageBox from "./components/MessageBox.vue";


const router = inject('router');
const messageBox = ref();
const headerIcons = ref();
const authApi = ref();

const CHANNEL_PRIVATE = 'private';
const CHANNEL_PUBLIC = 'public';
const channel_selected = ref(CHANNEL_PUBLIC);
const selectChannel = (channel) => {
    channel_selected.value = channel;
};

provide('channel',{
    CHANNEL_PRIVATE,
    CHANNEL_PUBLIC,
    channel_selected,
    selectChannel,
});

const file_filter = ref({
  language: {
    options: {de:'DE',en:'EN'},
    active: true,
    value: ''
  }
});

const setDefaultLanguage = function () {
  if(file_filter.value.language.value.length > 0){
    return false;
  }
  let browser_language = navigator.language.substring(0,2).toLowerCase();
  if(file_filter.value.language.options.hasOwnProperty(browser_language)){
    file_filter.value.language.value = browser_language;
  }else{
    file_filter.value.language.value = 'en';
  }
};

const setFilter = (filter) => {
  filter.value = Object.assign({},filter);
};

provide('filter',{
  file_filter,
  setFilter
});

const getAuthBearer = () => {
  try {
    return authApi.value.getBearer();
  }catch (e){
    return false;
  }
};

const userLoggedIn = computed(() => {
  try {
    return authApi.value.userLoggedIn;
  }catch (e){
    return false;
  }
});

const openLogin = (callback = false | '') => {
  try {
    return authApi.value.openLoginModal(callback);
  }catch (e){
    return false;
  }
};

provide('userApi',{
  getAuthBearer,
  userLoggedIn,
  openLogin
});

const shareLink = function (url) {
  navigator.clipboard.writeText(url).then(() => {
    showMessage('Share Link','Link to this file is added to your clipboard.', 'success', 'share');
  });
}

const showMessage = function (header, message, type = 'success', icon) {
  messageBox.value.addMessage(header,message, type, icon);
};

const setHeaderIcons = function (icons) {
  headerIcons.value.setIcons(icons);
};

provide('shareLink',shareLink);
provide('showMessage',showMessage);

onMounted(()=>{
  setDefaultLanguage();
})

</script>

<template>
  <Auth ref="authApi"></Auth>
  <Header ref="headerIcons"></Header>
  <router-view @set-open-file-icon="setHeaderIcons"></router-view>
  <Footer @share-link="shareLink"></Footer>
  <message-box ref="messageBox"></message-box>
</template>